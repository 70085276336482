export type ProductMetadataType = {
	synopsis: string;
	notice: string;
	credits: string;
	includes: string;
};

export const productMetadataMapping: ProductMetadataType = {
	synopsis: "Description",
	notice: "FAQs",
	credits: "How to Get Certified",
	includes: "Recommended Study",
};


const metadataMappings: Record<number, ProductMetadataType> = {
	1: {
		synopsis: "Description",
		notice: "FAQs",
		credits: "How to Get Certified",
		includes: "Recommended Study",
	},
	2: {
		synopsis: "Description",
		notice: "More Info",
		credits: "How to Get Certified",
		includes: "Recommended Study",
	},
};

export const getProductMetadata = (mappingType: number): ProductMetadataType => {
	return metadataMappings[mappingType] || metadataMappings[1]; 
};