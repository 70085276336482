import { FC, useEffect } from "react";
import { Route, Routes } from "react-router-dom";
import "./styles/App.css";
import { ClientSite } from "./ClientSite";
import { Wizard } from "../components/Wizard";
import { BJS } from "../pages/Subservices/BJS";
import { CPWM } from "../pages/Subservices/CPWM";
import { PJW } from "../pages/Subservices/PJW";
import { APSS } from "../pages/Subservices/APSS";
import { BOC } from "../pages/Subservices/BOC";
import { EXXM } from "../pages/Subservices/ExxpressMart";
import useWizardContext from "../context/wizard/hooks/useWizardContext";
import { CIRK } from "../pages/Subservices/CIRK";
import { CHPE } from "../pages/Subservices/CHPE";

const App: FC = () => {
	useEffect(() => {
		function blockIndexing() {
			const meta = document.createElement("meta");
			meta.name = "robots";
			meta.content = "noindex";
			window.document.head.appendChild(meta);
			console.info(
				`${window.location.host} blocked robots from indexing.`
			);
		}
		if (window.location.host.includes("staging")) blockIndexing();
	}, []);

	const {
		state: { wizardRequested, wizardUrl },
	} = useWizardContext();

	return (
		<>
			{wizardRequested && <Wizard src={wizardUrl} />}
			<Routes>
				<Route path="/*" element={<ClientSite />} />

				{/* SUBSERVICES */}
				<Route path="/bjs/*" element={<BJS />} />
				<Route path="/cpwm/*" element={<CPWM />} />
				<Route path="/pjwrg/*" element={<PJW />} />
				<Route path="/aplus/*" element={<APSS />} />
				<Route path="/boc/*" element={<BOC />} />
				<Route path="/circlek/*" element={<CIRK />} />
				<Route path="/chpe/*" element={<CHPE />} />
				{["/exxpressmart/*", "/exxpressmart.html/*"].map(
					(path, index) => (
						<Route key={index} path={path} element={<EXXM />} />
					)
				)}
			</Routes>
		</>
	);
};

export default App;
