import { Box, Container, Grid, Typography } from "@mui/material";
import { Footer } from "../../components/Footer";
import { Navbar } from "../../components/Navbar";
import { Products } from "../../components/Products";
import { Qualifiers } from "../../components/Qualifiers";
import { navItems } from "../navItems";
import useQualifierContext from "../../../../context/qualifiers/hooks/useQualifierContext";

const Home = () => {
	const {
		state: {
			service: {
				name,
				logoUrl,
				bannerImage,
				colorPrimary,
				colorSecondary,
			},
		},
	} = useQualifierContext();

	const fontFamily = "Roboto, Helvetica Neue, Helvetica, Arial, sans-serif";
	const colorWhite = "var(--color-white)";
	const colorBlack = "var(--color-black)";

	return (
		<>
			<Box
				sx={{
					display: "flex",
					flexDirection: "column",
					minHeight: "100vh",
				}}
			>
				<Grid
					container
					columns={12}
					sx={{ fontFamily, maxWidth: "80%", mx: "auto" }}
				>
					<Grid item lg={12} md={12} sm={12} xs={12}>
						{logoUrl && (
							<Navbar
								navItems={navItems}
								backgroundColor={colorWhite}
								color={colorBlack}
								hamburgerColor={colorPrimary}
								logoUrl={logoUrl}
								service={name}
							/>
						)}
					</Grid>
					<Grid
						item
						lg={12}
						md={12}
						sm={12}
						xs={12}
						sx={{
							backgroundImage: bannerImage,
							backgroundRepeat: "no-repeat",
							backgroundSize: "cover",
							backgroundPosition: "center 51%",
							mx: "auto",
							height: 400,
							top: 0,
							left: 0,
						}}
					/>
					<Grid
						item
						lg={12}
						md={12}
						sm={12}
						xs={12}
						sx={{
							minHeight: "40vh",
							mx: "auto",
						}}
					>
						<Grid container columns={12}>
							<Grid
								item
								lg={8}
								md={8}
								sm={10}
								xs={11}
								sx={{
									mx: "auto",
									my: "30px",
									backgroundColor: "rgba(255,255,255,0.9)",
									padding: "20px",
									borderRadius: "10px",
								}}
							>
								<Container sx={{ mb: 6, textAlign: "center" }}>
									<Typography
										variant="h5"
										fontWeight="bold"
										textTransform="uppercase"
									>
										Welcome to Chickie&apos;s &amp;
										Pete&apos;s Responsible Alcohol Training
										Program.
									</Typography>
									<Typography variant="h6" paddingTop={'1em'}>
										Please select the state that you work in
										and complete your Alcohol Training
										Program. Your certificate is provided to
										you at no cost by Chickie&apos;s &amp;
										Pete&apos;s and valid for 2 years.
									</Typography>
									<Typography variant="h6" paddingTop={'1em'}>
										If you have any questions or difficulty
										signing up, please reach out to your
										restaurant manager or to
										hrsupport@chickiesandpetes.com, or 215-
										604-9960 x 1.
									</Typography>
								</Container>{" "}
								<Qualifiers />
								<Products
									primaryColor={colorPrimary ?? ""}
									secondaryColor={colorSecondary ?? ""}
									purchaseButtonName={"Register now"}
									productMappingType={2}
								/>
							</Grid>
						</Grid>
					</Grid>
				</Grid>
				<Box sx={{ marginTop: "auto" }}>
					<Footer backgroundColor={colorWhite} color={colorBlack} />
				</Box>
			</Box>
		</>
	);
};

export default Home;
