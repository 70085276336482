import { Box, Container, Grid, Typography } from "@mui/material";
import { Footer } from "../../components/Footer";
import { Navbar } from "../../components/Navbar";
import { Products } from "../../components/Products";
import { Qualifiers } from "../../components/Qualifiers";
import { navItems } from "../navItems";
import services from "../../../../service.map";
const fontFamily = "Roboto, Helvetica Neue, Helvetica, Arial, sans-serif";
const footerTextColor = "white";
const primaryColor = "#000";
const secondaryColor = "#007aff";
const backgroundImage = `url(${process.env.REACT_APP_S3_IMAGE_BASE_URL}/pjw/pjwrg_logo.png)`;

const Home = () => {
	return (
		<>
			<Box
				sx={{
					display: "flex",
					flexDirection: "column",
					minHeight: "100vh",
				}}
			>
				<Grid container columns={12} sx={{ fontFamily }}>
					<Grid item lg={12} md={12} sm={12} xs={12}>
						<Navbar
							logoMaxWidth="240px"
							navItems={navItems}
							backgroundColor={primaryColor}
							service="pjw"
							servicePath="pjwrg"
							logoUrl="pjw_header.png"
							hamburgerColor="white"
							color="white"
							filterLevel={services.pjwrg.filterLevel! + 1}
							parentId={services.pjwrg.parentId}
							hideProductLevel={services.pjwrg.hideProductLevel}
						/>
					</Grid>
					<Grid
						item
						lg={12}
						md={12}
						sm={12}
						xs={12}
						sx={{
							backgroundColor: "white",
							mx: "auto",
							height: 50,
							top: 0,
							left: 0,
						}}
					/>
					<Grid
						item
						lg={12}
						md={12}
						sm={12}
						xs={12}
						sx={{
							backgroundImage,
							backgroundRepeat: "no-repeat",
							backgroundSize: "contain",
							backgroundColor: primaryColor,
							backgroundPosition: "50% 37%",
							mx: "auto",
							height: 250,
							top: 0,
							left: 0,
						}}
					/>

					<Grid
						item
						lg={12}
						md={12}
						sm={12}
						xs={12}
						sx={{
							minHeight: "50vh",
							mx: "auto",
						}}
					>
						<Grid container columns={12}>
							<Grid
								item
								lg={6}
								md={8}
								sm={10}
								xs={11}
								sx={{
									mx: "auto",
									my: "30px",
									backgroundColor: "rgba(255,255,255,0.9)",
									padding: "30px",
									borderRadius: "10px",
								}}
							>
								<Container sx={{ mb: 6, textAlign: "center" }}>
									<Typography
										variant="h5"
										fontWeight="bold"
										textTransform="uppercase"
									>
										Required Training for PJW&#39;s
										Associates
									</Typography>
									<Typography variant="h6">
										Register. Complete Your Training.
										Download Your Certificate.
									</Typography>
								</Container>
								<Qualifiers />
								<Products
									primaryColor={primaryColor}
									secondaryColor={secondaryColor}
									productMappingType={2}
								/>
							</Grid>
						</Grid>
					</Grid>
					<Grid
						item
						lg={12}
						md={12}
						sm={12}
						xs={12}
						sx={{ marginTop: "auto" }}
					></Grid>
				</Grid>
				<Box sx={{ marginTop: "auto" }}>
					<Footer
						backgroundColor={primaryColor}
						color={footerTextColor}
					/>
				</Box>
			</Box>
		</>
	);
};

export default Home;
